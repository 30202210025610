@import 'normalize.css';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100vw;
  overflow: auto;
}

#root {
  height: 100%;
  width: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
hr,
a,
ol,
li {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('./fonts/GothamPro.woff') format('woff'),
    url('./fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('./fonts/GothamPro-Bold.woff') format('woff'),
    url('./fonts/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('./fonts/GothamPro-Medium.woff') format('woff'),
    url('./fonts/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
