.slide-main {
  color: white;
  transition: clip-path 1s ease, transform 1s ease;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  &.swiper-slide-active {
    clip-path: polygon(0 0, 90% 0, 80% 100%, 0% 100%);
  }

  &.swiper-slide-next {
    transform: translateX(-20%);
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);

    & + .slide-main {
      transform: translateX(-20%);
    }
  }

  &:last-child.swiper-slide-active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

@media(max-width: 991px) {
  .slide-main {
    &.swiper-slide-active {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    &.swiper-slide-next {
      transform: translateX(0);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  
      & + .slide-main {
        transform: translateX(0);
      }
    }
  }
}